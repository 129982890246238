import React from 'react';
import { useDispatch } from 'react-redux';
import { resetForm } from '../../store/modules/form/actions';
import { withRouter } from 'react-router-dom';

function Congrats(props) {
  const dispatch = useDispatch();

  return (
    <div className="container">
      <div className="row bg-gradient" style={{ height: 70 }}>
        <h4
          className="text-white"
          style={{
            marginTop: 20,
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          Questionário sociodemográfico
        </h4>
      </div>
      <div
        className="row"
        style={{
          height: window.innerHeight - 160 + 'px',
          color: '#2196f3',
        }}
      >
        <div className="container text-center">
          <h1 style={{ paddingTop: 40, paddingBottom: 30 }}>Parabéns</h1>
          <h3>
            Você acaba de concluir o seu questionário! Estas informações serão
            muito úteis. Obrigado.
          </h3>
        </div>
      </div>
      <div className="row" style={{ height: 70, marginTop: 20 }}>
        <div className="container">
          <button
            onClick={() => {
              dispatch(resetForm());
              props.history.push('/');
            }}
            className="btn btn-block btn-custom btn-lg bg-gradient"
          >
            Sair
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Congrats);
