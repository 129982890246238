import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../store/modules/form/actions';

function InputDate(props) {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState({
    start: null,
    end: null,
    isNull: false,
  });
  const [isNull, setIsNull] = useState(false);

  return (
    <>
      <h5 className="questionTitle">
        {props.id} - {props.title}
      </h5>

      {!props.variant && (
        <input
          type="date"
          disabled={isNull}
          onChange={(e) => {
            dispatch(selectOption(props.id, e.target.value));
          }}
          className="form-control"
        />
      )}
      {props.variant === 'period' && (
        <div class="row">
          <div className="col-6">
            <label>Início</label>
            <input
              type="date"
              placeholder="from"
              disabled={isNull}
              className="form-control"
              onChange={(e) => {
                setPeriod({ ...period, start: e.target.value });
                dispatch(selectOption(props.id, period));
              }}
            />
          </div>
          <div className="col-6" style={{ paddingLeft: 0 }}>
            <label>Final</label>
            <input
              type="date"
              placeholder="from"
              disabled={isNull}
              className="form-control"
              onChange={(e) => {
                setPeriod({ ...period, end: e.target.value });
                dispatch(selectOption(props.id, period));
              }}
            />
          </div>
        </div>
      )}

      {props.allowDisabled && (
        <label
          className="containerRadio"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          {props.allowDisabled}
          <input
            id={props.id}
            type="checkbox"
            className="checkbox_custom"
            onChange={() => {
              setIsNull(!isNull);
              setPeriod({ ...period, isNull: !isNull });
              dispatch(selectOption(props.id, { ...period, isNull: !isNull }));
            }}
          />{' '}
          <span class="checkmark checkbox"></span>
        </label>
      )}
    </>
  );
}

export default InputDate;
