import React from 'react';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../store/modules/form/actions';

function InputText(props) {
  const dispatch = useDispatch();

  return (
    <>
      <h5 className="questionTitle">
        {props.id} - {props.title}
      </h5>
      <input
        type="text"
        onChange={(e) => {
          dispatch(selectOption(props.id, e.target.value));
        }}
        className="form-control"
      />
    </>
  );
}

export default InputText;
