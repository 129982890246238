import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../store/modules/form/actions';

function DropDown(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectOption(props.id, props.options[0].value));
  }, []);

  const [isNull, setIsNull] = useState(false);
  const [finalValue, setFinalValue] = useState(props.options[0].value);

  return (
    <>
      <h5 className="questionTitle">
        {props.id} - {props.title}
      </h5>
      <select
        className="form-control"
        disabled={isNull}
        onChange={(e) => {
          dispatch(selectOption(props.id, e.target.value));
          setFinalValue(e.target.value);
        }}
      >
        {props.options.map((option) => (
          <option selected={finalValue === option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {props.allowDisabled && (
        <label
          className="containerRadio"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          {props.allowDisabled.title}
          <input
            id={props.id}
            type="checkbox"
            className="checkbox_custom"
            onChange={() => {
              setIsNull(!isNull);
              setFinalValue(props.allowDisabled.option);
              dispatch(selectOption(props.id, props.allowDisabled.option));
            }}
          />{' '}
          <span class="checkmark checkbox"></span>
        </label>
      )}
    </>
  );
}

export default DropDown;
