import React from 'react';

import { Link } from 'react-router-dom';

function Presentation() {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-12"
          style={{
            textAlign: 'center',
            color: '#4c2168',
            paddingTop: 50,
          }}
        >
          <h1>Bem-Vindo!</h1>
          <p style={{ marginTop: 50, marginBottom: 100 }}>
            Seja bem-vindo ao "Aconselha HSH"! Este aplicativo funciona como uma
            ferramenta de apoio ao profissional de saúde que realizará o
            aconselhamento com você! O recurso é especialmente direcionado a
            homens que fazem sexo com homens e permite que você tenha um
            atendimento personalizado. Ao preencher o questionário a seguir,
            suas respostas serão direcionadas ao aconselhador. O questionário é
            anônimo e nenhuma informação será compartilhada sem o seu
            consentimento. Vamos começar?
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Link
            to="/terms"
            className="btn btn-block btn-custom btn-lg bg-gradient"
          >
            Aceitar
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
