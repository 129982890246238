import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { nextPageRequest } from "../../store/modules/form/actions";
import "./style.css";

import ButtonSelect from "../../components/ButtonSelect";
import Dropdown from "../../components/Dropdown";
import InputDate from "../../components/InputDate";
import InputNumber from "../../components/InputNumber";
import InputText from "../../components/InputText";
import RadioButtonHorizontal from "../../components/RadioButtonHorizontal";
import RadioButtonVertical from "../../components/RadioButtonVertical";
import RadioButtonVerticalMultiple from "../../components/RadioButtonVerticalMultiple";

function App(props) {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.form);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(Math.round((formData.page / formData.form.length) * 100));
  }, [formData.page]);

  const components = {
    ButtonSelect,
    Dropdown,
    InputDate,
    InputNumber,
    InputText,
    RadioButtonHorizontal,
    RadioButtonVertical,
    RadioButtonVerticalMultiple,
  };

  const formTitles = {
    //16: 'Qual foi sua frequência de uso de drogas no último ano?',
    17: "Sobre o uso de drogas e álcool, responda:",
    //18: 'Fazer sexo anal com homens sem preservativo para mim é algo...',
    20: "Qual a frequência do uso de álcool?",
    29: "Fazer sexo anal com homens sem preservativo para mim é algo...",
    //21: 'Fazer sexo anal com homens sem preservativo para mim é algo que...',
    22: "Fazer sexo anal com homens sem preservativo para mim é algo...",
    23: "Fazer sexo anal com homens sem preservativo para mim é algo...",
    24: "Fazer sexo anal com homens sem preservativo para mim é algo...",
    25: "Fazer sexo anal com homens sem preservativo para mim é algo...",
  };

  const formulario = formData.form;

  return (
    <div className="container">
      <div className="row">
        <div class="progress" style={{ width: "100%" }}>
          <div
            class="progress-bar bg-success"
            role="progressbar"
            style={{
              width: `${progress}%`,
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <div className="row bg-gradient" style={{ height: 70 }}>
        <h4
          className="text-white"
          style={{
            marginTop: 20,
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          Questionário sociodemográfico
        </h4>
      </div>
      <div
        className="row"
        id="mainContainerForm"
        style={{
          height: window.innerHeight - 180 + "px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="container">
          {formulario.map((formPage, index) => (
            <div
              id={index}
              className={
                index === formData.page ? "pageActive" : "pageInactive"
              }
            >
              {index === 4 && (
                <div class="alert alert-success" style={{ marginTop: 15 }}>
                  Utilizamos as siglas de Doenças Sexualmente Transmissíveis
                  (DST) e Infecções Sexualmente Transmissíveis (IST) para
                  facilitar a compreensão do público geral.
                </div>
              )}

              {index === 5 && (
                <div class="alert alert-success" style={{ marginTop: 15 }}>
                  PEP é uma medida de prevenção de urgência à infecção pelo HIV,
                  hepatites virais e outras infecções sexualmente transmissíveis
                  (IST), que consiste no uso de medicamentos para reduzir o
                  risco de adquirir essas infecções.
                </div>
              )}

              {index === 7 && (
                <div class="alert alert-success" style={{ marginTop: 15 }}>
                  A PREP consiste no uso preventivo de medicamentos
                  antirretrovirais antes da exposição sexual ao vírus, para
                  reduzir a probabilidade de infecção pelo HIV.
                </div>
              )}

              {index === 16 && (
                <div class="alert alert-success" style={{ marginTop: 15 }}>
                  Essa é uma escala de 7 pontos que permite uma gradação entre
                  os extremos que variam de 1 até 7, onde 1 representa pouco e 7
                  muito.
                </div>
              )}

              {formTitles[index] && (
                <h5 className="questionTitle">{formTitles[index]}</h5>
              )}
              {formPage.map((question) => {
                return React.createElement(components[question.type], question);
              })}
            </div>
          ))}
        </div>
      </div>
      <div className="row" style={{ height: 70, marginTop: 20 }}>
        <div className="container">
          <button
            className="btn btn-block btn-custom btn-lg bg-gradient"
            onClick={() => {
              dispatch(nextPageRequest(props.history));
            }}
          >
            Avançar
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(App);
