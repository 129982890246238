import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { saveForm } from '../../store/modules/form/actions';
import { withRouter } from 'react-router-dom';

function UserCode(props) {
  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const [destination, setDestination] = useState('app');

  useEffect(() => {
    if (form.page < form.form.length - 1) {
      //props.history.push('/app');
      setDestination('app');
    } else {
      setDestination('parabens');
      dispatch(saveForm(form));
    }
  }, []);

  return (
    <div className="container">
      <div
        className="row"
        style={{
          height: window.innerHeight - 110 + 'px',
          color: '#2196f3',
        }}
      >
        <div className="container text-center" style={{ paddingTop: 40 }}>
          <h3>O seu código de atendimento.</h3>
          <h3>
            Seus dados estão guardados em sigilo. Mostre este código para o
            profissional responsável por seu atendimento.
          </h3>

          <input
            type="text"
            className="form-control"
            value={form.id}
            readOnly
            style={{
              textAlign: 'center',
              marginTop: 40,
              marginBottom: 40,
              fontSize: 25,
            }}
          />
        </div>
      </div>
      <div className="row" style={{ height: 70, marginTop: 20 }}>
        <div className="container">
          <Link
            to={`/${destination}`}
            className="btn btn-block btn-custom btn-lg bg-gradient"
          >
            Avançar
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withRouter(UserCode);
