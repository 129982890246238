import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../store/modules/form/actions';

function RadioButtonHorizontal(props) {
  const [selectionado, setSelecionado] = useState();
  const [isNull, setIsNull] = useState(false);

  const dispatch = useDispatch();
  const setValue = (value) => {
    dispatch(selectOption(props.id, value, undefined));
    setSelecionado(value);
  };

  useEffect(() => {
    //dispatch(selectOption(props.id, 1));
  }, []);

  return (
    <>
      <h5 className="questionTitle">
        {props.id} - {props.title}
      </h5>
      <div className="row">
        <div className="col-6">
          <span className="indicatorTitle">{props.min}</span>
        </div>
        <div className="col-6 text-right">
          <span className="indicatorTitle">{props.max}</span>
        </div>
      </div>
      <div className="containerRadioHorizontalWrapper">
        {[1, 2, 3, 4, 5, 6, 7].map((n) => (
          <label className="containerRadio radioHorizontal">
            <input
              type="radio"
              value={n}
              disabled={isNull}
              checked={selectionado === n}
              name={props.id}
              onChange={() => setValue(n)}
            />
            <span class="checkmark"></span>
          </label>
        ))}
      </div>
      {props.allowDisabled && (
        <label
          className="containerRadio radioHorizontal"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <input
            id={props.id}
            type="checkbox"
            className=""
            onChange={() => {
              setIsNull(!isNull);
              setValue(null);
            }}
          />{' '}
          <span class="checkmark checkbox"></span>
          <label className="labe_checkbox" for={props.id}>
            {props.allowDisabled}
          </label>
        </label>
      )}
    </>
  );
}

export default RadioButtonHorizontal;
