import produce from "immer";
import uniqid from "uniqid";
import Swal from "sweetalert2";

const periodicityOptions = [
  { value: undefined, label: "Selecionar..." },
  { value: 1, label: "Nunca" },
  { value: 2, label: "1 ou 2 vezes" },
  { value: 3, label: "Mensalmente" },
  { value: 4, label: "Semanalmente" },
  { value: 5, label: "Diáriamente ou quase todos os dias" },
];

const numberOptions = [
  { value: 1, label: "1x" },
  { value: 2, label: "2x" },
  { value: 3, label: "3x" },
  { value: 4, label: "Mais de 5x" },
  { value: 5, label: "Mais de 10x" },
  { value: 6, label: "Mais de 20x" },
];

const INITIAL_STATE = {
  id: uniqid.time(),
  page: 0,
  nextPage: undefined,
  questions: {},
  form: [
    // 0
    [
      {
        id: 1,
        title: "Você já preencheu esse questionário antes?",
        type: "ButtonSelect",
        options: [
          { value: "S", label: "Sim" },
          { value: "N", label: "Não" },
        ],
      },
    ],
    // 1
    [
      {
        id: 2,
        title: "Qual a sua idade?",
        type: "InputNumber",
        min: 18,
      },
      {
        id: 3,
        title: "Qual a sua escolaridade?",
        type: "RadioButtonVertical",
        options: [
          { value: 1, label: "Sem escolaridade" },
          { value: 2, label: "Ensino fundamental incompleto" },
          { value: 3, label: "Ensino fundamental completo" },
          { value: 4, label: "Ensino médio incompleto" },
          { value: 5, label: "Ensino médio completo" },
          { value: 6, label: "Ensino superior incompleto" },
          { value: 7, label: "Ensino superior completo" },
          { value: 8, label: "Pós-graduação" },
        ],
      },
    ],
    // 2
    [
      {
        id: 4,
        title: "Por qual motivo você veio realizar o teste rápido?",
        type: "RadioButtonVerticalMultiple",
        options: [
          { value: 1, label: "Conhecimento de estado sorológico" },
          { value: 2, label: "Relação sexual desprotegida " },
          { value: 3, label: "Rompimento do preservativo" },
          { value: 4, label: "Conferir resultado anterior" },
          { value: 5, label: "Janela Imunológica" },
          { value: 6, label: "Início de relacionamento" },
          { value: 7, label: "Finalizando relacionamento" },
          { value: 8, label: "Parceiro com resultado de HIV positivo" },
          { value: 9, label: "Sintomas relacionados a Aids" },
          { value: 10, label: "Sintomas de outra IST / DST" },
          { value: 11, label: "Encaminhamento de outros serviços de saúde" },
          { value: 12, label: "Violência Sexual" },
          { value: 13, label: "Outros" },
        ],
      },
      {
        id: 5,
        title: "Você já fez o teste de HIV/Aids anteriormente? ",
        type: "ButtonSelect",
        options: [
          { value: "S", label: "Sim" },
          { value: "N", label: "Não", nextPage: 4 },
          { value: "D", label: "Desconhece", nextPage: 4 },
        ],
      },
    ],
    // 3
    [
      {
        id: 6,
        title: "Quantas vezes você já fez o teste de HIV/Aids?",
        //type: 'InputNumber',
        type: "Dropdown",
        options: numberOptions,
      },

      {
        id: 7,
        title: "O último resultado obtido foi: ",
        type: "ButtonSelect",
        options: [
          { value: "N", label: "HIV negativo" },
          { value: "P", label: "HIV positivo" },
        ],
      },
    ],
    // 4
    [
      {
        id: 8,
        title: "Nos últimos 12 meses, teve alguma DST/IST?",
        type: "ButtonSelect",
        options: [
          { value: "S", label: "Sim" },
          { value: "N", label: "Não" },
          { value: "D", label: "Desconhece" },
        ],
      },
    ],
    // 5
    [
      {
        id: 9,
        title: "Você já fez uso da Profilaxia Pós-Exposição (PEP)?",
        type: "ButtonSelect",
        options: [
          { value: "S", label: "Sim" },
          { value: "N", label: "Não", nextPage: 7 },
          { value: "D", label: "Desconhece", nextPage: 7 },
        ],
      },
    ],
    // 6
    [
      {
        id: 10,
        title: "Quantas vezes?",
        type: "InputNumber",
      },
      {
        id: 11,
        title: "Última data de uso:",
        type: "InputDate",
        allowDisabled: "Não lembro",
      },
    ],
    // 7
    [
      {
        id: 12,
        title: "Você usa ou já usou a Profilaxia Pré-Exposição (PREP)?",
        type: "ButtonSelect",
        options: [
          { value: "S", label: "Sim" },
          { value: "N", label: "Não", nextPage: 9 },
          { value: "D", label: "Desconhece", nextPage: 9 },
        ],
      },
    ],
    // 8
    [
      {
        id: 13,
        title: "Se usa, data de início do uso:",
        type: "InputDate",
        allowDisabled: "Não lembro",
      },
      {
        id: 14,
        title: "Se já usou, período de uso:",
        type: "InputDate",
        variant: "period",
        allowDisabled: "Não lembro",
      },
    ],
    // 9
    [
      {
        id: 15,
        title: "Mantenho relações sexuais:",
        type: "RadioButtonVertical",
        options: [
          { value: 1, label: "Somente com homens" },
          {
            value: 2,
            label: "Preferentemente com homens e esporadicamente com mulheres",
          },
          { value: 3, label: "Tanto com homens como com mulheres" },
          {
            value: 4,
            label: "Preferentemente com mulheres e esporadicamente com homens",
          },
        ],
      },
    ],
    // 10
    [
      {
        id: 16,
        title:
          "Nos últimos 12 meses, aproximadamente quantos parceiros sexuais você teve?",
        type: "InputNumber",
      },
    ],
    // 11
    [
      {
        id: 17,
        title:
          "Nos últimos 12 meses, aproximadamente com quantos parceiros sexuais fez sexo anal sem preservativo? ",
        type: "InputNumber",
      },
      {
        id: 18,
        title:
          "Nos últimos 12 meses, quantos parceiros sexuais conheceu através de aplicativos de celular? ",
        type: "InputNumber",
      },
    ],
    // 12
    [
      {
        id: 19,
        title:
          "Entre os parceiros sexuais que conheceste por aplicativo, aproximadamente com quantos fez sexo anal sem preservativo?",
        type: "InputNumber",
      },
    ],
    // 13
    [
      {
        id: 20,
        title: "Quais o motivo do não uso do preservativo com parceiro fixo?",
        type: "RadioButtonVerticalMultiple",
        options: [
          { value: 15, label: "Não tenho parceiro fixo" },
          { value: 1, label: "Não gosto" },
          { value: 2, label: "Não acredito na eficácia" },
          { value: 3, label: "Parceiro não aceita" },
          { value: 4, label: "Não tinha no momento" },
          { value: 5, label: "Confio no parceiro" },
          { value: 6, label: "Sob o efeito de álcool e/ou drogas" },
          { value: 7, label: "Não consigo negociar com o parceiro" },
          { value: 8, label: "Achei que o parceiro não tinha HIV" },
          { value: 9, label: "Acreditava que não iria pegar" },
          { value: 10, label: "Não deu tempo - tesão" },
          { value: 11, label: "Não tinha condições de comprar" },
          { value: 12, label: "Violência sexual" },
          { value: 13, label: "Alergia ao produto" },
          { value: 14, label: "Sempre uso o preservativo / teste de rotina" },
          { value: 15, label: "Uso o preservativo / Teste de rotina" },
          { value: 16, label: "Outros" },
        ],
      },
      {
        id: 21,
        title:
          "Quais o motivo do não uso do preservativo com parceiro eventual?",
        type: "RadioButtonVerticalMultiple",
        options: [
          { value: 15, label: "Não tenho parceiros eventuais" },
          { value: 1, label: "Não gosta" },
          { value: 2, label: "Não acredito na eficácia" },
          { value: 3, label: "Parceiro não aceita" },
          { value: 4, label: "Não tinha no momento" },
          { value: 5, label: "Confio no parceiro" },
          { value: 6, label: "Sob o efeito de álcool - drogas" },
          { value: 7, label: "Não consigo negociar com o parceiro" },
          { value: 8, label: "Achei que o parceiro não tinha HIV" },
          { value: 9, label: "Acreditava que não iria pegar" },
          { value: 10, label: "Não deu tempo - tesão" },
          { value: 11, label: "Não tinha condições de comprar" },
          { value: 12, label: "Violência sexual" },
          { value: 13, label: "Alergia ao produto" },
          { value: 14, label: "Outros" },
        ],
      },
      {
        id: 22,
        title: "Meu parceiro/meus parceiros sexuais tem HIV/Aids?",
        type: "ButtonSelect",
        options: [
          { value: "S", label: "Sim" },
          { value: "N", label: "Não" },
          { value: "D", label: "Desconheço" },
        ],
      },
      {
        id: 23,
        title:
          "Considero que tenho informações suficientes sobre transmissão HIV/Aids, DST/IST?",
        type: "ButtonSelect",
        options: [
          { value: "S", label: "Sim" },
          { value: "N", label: "Não" },
        ],
      },
    ],
    // 14
    [
      {
        id: 24,
        title: "Quando faço sexo anal:",
        type: "RadioButtonVertical",
        options: [
          { value: 1, label: "Sempre sou ativo" },
          {
            value: 2,
            label: "Sou preferencialmente ativo",
          },
          { value: 3, label: "Sou tanto ativo como passivo" },
          {
            value: 4,
            label: "Sou preferencialmente passivo",
          },
          {
            value: 6,
            label: "Sempre sou passivo",
          },
        ],
      },
    ],
    // 15
    [
      {
        id: 25,
        title: "Faço sexo anal sem preservativo:",
        type: "RadioButtonVertical",
        options: [
          { value: 1, label: "Somente com parceiro fixo" },
          {
            value: 2,
            label:
              "Preferencialmente com meu parceiro fixo e, às vezes, com parceiros eventuais",
          },
          {
            value: 3,
            label: "Tanto com meu parceiro fixo, como com parceiros eventuais",
          },
          {
            value: 4,
            label:
              "Preferencialmente com parceiros eventuais e às vezes com parceiro fixo",
          },
          {
            value: 6,
            label: "Somente com parceiros eventuais",
          },
          {
            value: 7,
            label: "Não se aplica a mim, pois sempre uso preservativo",
            nextPage: 16,
          },
        ],
      },
    ],
    // 16
    [
      {
        id: 26,
        title:
          "Pergunto para meus PARCEIROS FIXOS, com os quais tenho relações sem preservativos, se eles tem HIV/Aids (Se tiver):",
        type: "RadioButtonHorizontal",
        min: "Nunca uso preservativo",
        max: "Sempre uso preservativo",
        allowDisabled: "Não tenho parceiros fixos",
      },
      {
        id: 27,
        title:
          "Pergunto para meus PARCEIROS EVENTUAIS, com os quais tenho relações sem preservativos,  se eles têm HIV/Aids (Se tiver):",
        type: "RadioButtonHorizontal",
        min: "Nunca",
        max: "Sempre",
        allowDisabled: "Não tenho parceiros eventuais",
      },
    ],
    // 17
    [
      {
        id: 28,
        title: "O consumo de álcool ou Drogas:",
        type: "RadioButtonVertical",
        options: [
          {
            value: 1,
            label: "Não uso álcool ou drogas.",
            nextPage: 22,
          },
          {
            value: 2,
            label: "Somente álcool",
            nextPage: 18,
          },
          {
            value: 3,
            label: "Álcool e drogas",
            nextPage: 18,
          },
          {
            value: 4,
            label: "Somente drogas",
            nextPage: 19,
          },
        ],
      },
    ],
    //18
    [
      {
        id: 29,
        title: "O consumo de álcool (Se usar):",
        type: "RadioButtonHorizontal",
        min: "Me dificulta o uso do preservativo",
        max: "Me facilita o uso do preservativo",
      },
    ],
    //19
    [
      {
        id: 30,
        title: "O consumo de outras drogas (Se usar):",
        type: "RadioButtonHorizontal",
        min: "Me dificulta o uso do preservativo",
        max: "Me facilita o uso do preservativo",
      },
    ],
    // 20
    [
      {
        id: 31,
        title: "Álcool",
        type: "Dropdown",
        options: periodicityOptions.filter((p) => p.value !== 1),
        /*allowDisabled: {
          title: 'Nunca fiz isso',
          option: 1,
        },*/
      },
    ],
    // 21
    [
      {
        id: 32,
        title: "Maconha",
        type: "Dropdown",
        options: periodicityOptions,
        allowDisabled: {
          title: "Nunca fiz isso",
          option: 1,
        },
      },
      {
        id: 33,
        title: "Cocaína aspirada",
        type: "Dropdown",
        options: periodicityOptions,
        allowDisabled: {
          title: "Nunca fiz isso",
          option: 1,
        },
      },
      {
        id: 34,
        title: "Cocaína injetável",
        type: "Dropdown",
        options: periodicityOptions,
        allowDisabled: {
          title: "Nunca fiz isso",
          option: 1,
        },
      },
      {
        id: 35,
        title: "Crack",
        type: "Dropdown",
        options: periodicityOptions,
        allowDisabled: {
          title: "Nunca fiz isso",
          option: 1,
        },
      },
      {
        id: 36,
        title: "Heroína",
        type: "Dropdown",
        options: periodicityOptions,
        allowDisabled: {
          title: "Nunca fiz isso",
          option: 1,
        },
      },
      {
        id: 37,
        title:
          "Tranquilizantes sem recomendação médica (ex.: benzodiazepínico, rivotril, remédio para dormir, etc.) ",
        type: "Dropdown",
        options: periodicityOptions,
        allowDisabled: {
          title: "Nunca fiz isso",
          option: 1,
        },
      },
      {
        id: 38,
        title: "Anfetaminas (ex. bala, ecstasy, etc.)",
        type: "Dropdown",
        options: periodicityOptions,
        allowDisabled: {
          title: "Nunca fiz isso",
          option: 1,
        },
      },
      {
        id: 39,
        title: "Outra? Qual?",
        type: "InputText",
      },
      {
        id: 40,
        title: "Se outra, qual o período?  ",
        type: "Dropdown",
        options: periodicityOptions,
      },
    ],
    // 22
    [
      {
        id: 41,
        title: null,
        type: "RadioButtonHorizontal",
        min: "Que eu não gosto",
        max: "Que eu gosto",
      },
      {
        id: 42,
        title: null,
        type: "RadioButtonHorizontal",
        min: "Nada perigoso",
        max: "Muito perigoso",
      },
    ],
    // 23
    [
      {
        id: 43,
        title:
          "Poderia me transmitir o HIV/Aids ou DST/IST (gonorreia, sífilis, verrugas genitais, etc...):",
        type: "RadioButtonHorizontal",
        min: "Pouco provável",
        max: "Muito provável",
      },
      {
        id: 44,
        title:
          "Poderia fazer com que eu transmitisse HIV/Aids ou DST/IST (gonorreia, sífilis, verrugas gênitas, etc...) para outra pessoa:",
        type: "RadioButtonHorizontal",
        min: "Pouco provável",
        max: "Muito provável",
      },
    ],
    // 24
    [
      {
        id: 51,
        title: "Me deixaria com mais tesão:",
        type: "RadioButtonHorizontal",
        min: "Pouco provável",
        max: "Muito provável",
      },
      {
        id: 52,
        title: "Me faria sentir mais livre:",
        type: "RadioButtonHorizontal",
        min: "Pouco provável",
        max: "Muito provável",
      },
      {
        id: 53,
        title: "Faria meu parceiro sexual sentir mais prazer:",
        type: "RadioButtonHorizontal",
        min: "Pouco provável",
        max: "Muito provável",
      },
      {
        id: 54,
        title: "Me faria sentir maior compromisso na minha relação:",
        type: "RadioButtonHorizontal",
        min: "Pouco provável",
        max: "Muito provável",
      },
      {
        id: 55,
        title:
          "Faria com que meu parceiro sexual sentisse maior compromisso na relação:",
        type: "RadioButtonHorizontal",
        min: "Pouco provável",
        max: "Muito provável",
      },
    ],
    // 25
    [
      {
        id: 45,
        title: "Que a outra pessoa seja muito atraente:",
        type: "RadioButtonHorizontal",
        min: "Me dificulta o uso do preservativo",
        max: "Me facilita o uso do preservativo",
      },
      {
        id: 46,
        title: "Estar muito excitado...",
        type: "RadioButtonHorizontal",
        min: "Me dificulta o uso do preservativo",
        max: "Me facilita o uso do preservativo",
      },
      {
        id: 47,
        title:
          "Quando faço sexo anal sem preservativo, penso que algo de ruim vai acontecer:",
        type: "RadioButtonHorizontal",
        min: "Nunca",
        max: "Sempre",
      },
      {
        id: 48,
        title:
          "Quando eu acho que vou fazer sexo anal, vem rapidamente à minha cabeça o uso de preservativos:",
        type: "RadioButtonHorizontal",
        min: "Falso",
        max: "Verdadeiro",
      },
      {
        id: 49,
        title:
          "Fazer sexo anal sem preservativo durante o próximo mês é algo que:",
        type: "RadioButtonHorizontal",
        min: "Depende totalmente de mim ",
        max: "Não depende em nada de mim",
      },
      {
        id: 50,
        title: "Parar e pensar sobre as consequências:",
        type: "RadioButtonHorizontal",
        min: "Me dificulta o uso do preservativo",
        max: "Me facilita o uso do preservativo",
      },
    ],
    //26
    [
      {
        id: 56,
        title: "Conseguir preservativos é algo:",
        type: "RadioButtonHorizontal",
        min: "Fácil",
        max: "Difícil",
      },
      {
        id: 57,
        title: "Minha opinião sobre preservativo:",
        type: "RadioButtonHorizontal",
        min: "Me importa muito",
        max: "Nada",
      },
      {
        id: 58,
        title:
          "Eu pratico sexo anônimo (sexo casual em locais públicos, salas escuras, dark room, saunas, etc.):",
        type: "RadioButtonHorizontal",
        min: "Nunca",
        max: "Sempre",
      },
      {
        id: 59,
        title:
          "Nos últimos 12 meses você realizou sexo em troca de dinheiro ou por algum outro ganho material?",
        type: "ButtonSelect",
        options: [
          { value: "S", label: "Sim" },
          { value: "N", label: "Não" },
        ],
      },
    ],
    // 27
    [
      {
        id: 64,
        title: "Com esta(s) pessoas você usou preservativo:",
        type: "RadioButtonHorizontal",
        min: "Nunca",
        max: "Sempre",
      },
    ],
    // 28
    [
      {
        id: 60,
        title:
          "A maioria das pessoas que são importantes para mim pensa que eu:",
        type: "RadioButtonHorizontal",
        min: "Não deveria fazer sexo anal sem preservativo",
        max: "Deveria fazer sexo anal sem preservativo",
      },
      {
        id: 61,
        title:
          "Quero comportar-me como a maioria das pessoas que são importantes pra mim pensa que eu deveria fazer:",
        type: "RadioButtonHorizontal",
        min: "Falso",
        max: "Verdadeiro",
      },
      {
        id: 62,
        title:
          "Durante o próximo mês, tenho a intenção de fazer sexo anal sem preservativo:",
        type: "RadioButtonHorizontal",
        min: "Falso",
        max: "Verdadeiro",
      },
      {
        id: 63,
        title:
          "Durante o próximo ano, tenho a intenção de fazer sexo anal sem preservativo:",
        type: "RadioButtonHorizontal",
        min: "Falso",
        max: "Verdadeiro",
      },
      /*{
        id: 64,
        title:
          "Você recomendaria o preenchimento desse questionário a outra pessoa que viesse fazer suas testagens no serviço?",
        type: "RadioButtonHorizontal",
        min: "Sim",
        max: "Não",
      },*/
    ],
  ],
};

const verifyQuestions = (questions, responses) => {
  let res = { error: false };
  questions.map((q) => {
    /*const componentsRequireds = [
            'RadioButtonVertical',
            'RadioButtonHorizontal',
        ];

        // RECUPERANDO O TIPO DE COMPONENTE ORIGINAL PARA FAZER A VALIDAÇÃO
        const originaQuestion = INITIAL_STATE.form.flat()[q - 1];
        if (componentsRequireds.indexOf(originaQuestion.type) !== -1) {
            // VERIFICAR SE TEM O VALOR DELES NO RESPONSE
            console.log(responses);
            console.log(responses[q]);
    }*/

    // 39, 40 - campo texto opctional
    if (q != 39 && q != 40) {
      console.log(responses[q]);

      if (responses[q] === undefined) {
        res = { error: true, message: "Todos os campos são obrigatórios." };
      }

      // 2 - validar idade mínima
      if (q == 2) {
        if (responses[q] < 18) {
          res = { error: true, message: "A idade mínima é 18 anos." };
        }
      }

      // 13 - validar campos periodo
      if (q == 13 && !responses[q]?.isNull && !responses[q]) {
        res = { error: true, message: "Todos os campos são obrigatórios." };
      }

      // 14 - validar campos periodo
      if (q == 14 && !responses[q]?.isNull && !responses[q]) {
        if (!responses[q]?.start || !responses[q]?.end) {
          res = { error: true, message: "Todos os campos são obrigatórios." };
        }
      }

      // 26 - validar is null
      if (q == 26 && responses[q] === undefined) {
        res = { error: true, message: "Todos os campos são obrigatórios." };
      }

      // 27 - validar is null
      if (q == 27 && responses[q] === undefined) {
        res = { error: true, message: "Todos os campos são obrigatórios." };
      }

      // 19, FLOW CONDICIONAL
      if ((q == 18 && responses[q] == 0) || (q == 18 && !responses[q])) {
        res.nextPage = 13;
      }

      // 16, FLOW CONDICIONAL
      if ((q == 16 && responses[q] == 0) || (q == 16 && !responses[q])) {
        res.nextPage = 13;
      }

      if (q === 17 && parseInt(responses[q]) > parseInt(responses[16])) {
        res = {
          error: true,
          message: `Você inseriu um numero maior do que teve de relações sexuais ao total. Insira um número até ${responses[16]}`,
        };
      }

      if (q === 18 && parseInt(responses[q]) > parseInt(responses[16])) {
        res = {
          error: true,
          message: `Você inseriu um numero maior do que teve de relações sexuais ao total. Insira um número até ${responses[16]}`,
        };
      }

      if (q === 30 && responses[28] == 4) {
        res.nextPage = 21;
      }

      if (q === 31 && responses[28] == 2) {
        res.nextPage = 22;
      }

      // 59, FLOW CONDICIONAL
      if (q === 59 && responses[59] === "N") {
        res.nextPage = 28;
      }
    }
  });
  return res;
};

export default function address(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@form/NEXT_PAGE_REQUEST": {
      return produce(state, (draft) => {
        const questions = state.form[state.page].map((p) => p.id);
        const passVerify = verifyQuestions(questions, state.questions);
        if (!passVerify.error) {
          const nextPage = passVerify.nextPage
            ? passVerify.nextPage
            : state.nextPage > draft.page
            ? state.nextPage
            : null;

          console.log(passVerify.nextPage);
          console.log(state.nextPage);
          console.log(draft.page);
          console.log(nextPage);

          if (state.page < state.form.length - 1) {
            draft.page = nextPage ? nextPage : draft.page + 1;
          } else {
            action.history.push("/codigo");
          }
        } else {
          Swal.fire("Oops...", passVerify.message, "error");
        }
        document.getElementById("mainContainerForm").scrollTop = 0;
      });
    }
    case "@form/SELECT_OPTION": {
      return produce(state, (draft) => {
        draft.questions[action.payload.question] = action.payload.option;
        draft.nextPage = action.payload.nextPage;
      });
    }
    case "@form/RESET": {
      return produce(state, (draft) => {
        draft.id = uniqid.time();
        draft.page = 0;
        draft.nextPage = undefined;
        draft.questions = {};
        draft.form = INITIAL_STATE.form;
      });
    }
    default:
      return state;
  }
}
