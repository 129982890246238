import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../store/modules/form/actions';

function InputNumber(props) {
  const dispatch = useDispatch();
  const [inicialValue, setInitialValue] = useState(0);

  useEffect(() => {
    dispatch(selectOption(props.id, ''));
  }, []);

  return (
    <>
      <h5 className="questionTitle">
        {props.id} - {props.title}
      </h5>
      <input
        type="number"
        min="0"
        step="1"
        value={inicialValue}
        onChange={(e) => {
          //if (e.target.value > 0) {
          setInitialValue(e.target.value);
          dispatch(selectOption(props.id, e.target.value));
          //}
        }}
        className="form-control"
      ></input>
    </>
  );
}

export default InputNumber;
