import React from "react";
import { Provider } from "react-redux";
import store from "./store";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Splash from "./pages/Splash";
import Presentation from "./pages/Presentation";
import Terms from "./pages/Terms";
import App from "./pages/App";
import UserCode from "./pages/UserCode";
import Congrats from "./pages/Congrats";

export default function Routes() {
	return (
		<Provider store={store}>
			<div id="mainContent" style={{ height: window.innerHeight + "px" }}>
				<Router>
					<Switch>
						<Route path="/" exact component={Splash} />
						<Route
							path="/presentation"
							exact
							component={Presentation}
						></Route>
						<Route path="/terms" exact component={Terms}></Route>
						<Route path="/app" exact component={App}></Route>
						<Route
							path="/codigo"
							exact
							component={UserCode}
						></Route>
						<Route
							path="/parabens"
							exact
							component={Congrats}
						></Route>
					</Switch>
				</Router>
			</div>
		</Provider>
	);
}
