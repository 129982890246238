import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../store/modules/form/actions';

function RadioButtonVertical(props) {
  const [selectionado, setSelecionado] = useState();
  const dispatch = useDispatch();
  const setValue = (value, nextPage = undefined) => {
    dispatch(selectOption(props.id, value, nextPage));
    setSelecionado(value);
  };

  useEffect(() => {
    //dispatch(selectOption(props.id, 1));
  }, []);

  return (
    <>
      <h5 className="questionTitle">
        {props.id} - {props.title}
      </h5>
      {props.options.map((option) => (
        <label className="containerRadio">
          {option.label}
          <input
            type="checkbox"
            value={option.value}
            checked={selectionado === option.value}
            name={props.id}
            onChange={() => setValue(option.value, option.nextPage)}
          />
          <span class="checkmark"></span>
        </label>
      ))}
    </>
  );
}

export default RadioButtonVertical;
