import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../store/modules/form/actions';

function ButtonSelect(props) {
  const [selected, setSelected] = useState(1);
  const dispatch = useDispatch();

  const setValue = (value, nextPage = undefined) => {
    dispatch(selectOption(props.id, value, nextPage));
    setSelected(value);
  };

  return (
    <>
      <h5 className="questionTitle">
        {props.id} - {props.title}
      </h5>
      {props.options.map((option) => (
        <button
          className={`btn btn-block btn-primary ${
            selected === option.value ? 'btn-selected' : ''
          }`}
          value={option.value}
          onClick={() => setValue(option.value, option?.nextPage)}
        >
          {option.label}
        </button>
      ))}
    </>
  );
}

export default ButtonSelect;
