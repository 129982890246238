import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../store/modules/form/actions';

function RadioButtonVerticalMultiple(props) {
  const [selectionados, setSelecionados] = useState([]);
  const dispatch = useDispatch();
  const setValue = (value, nextPage = undefined) => {
    const exists = selectionados.includes(value);
    let newArray = [...selectionados];

    if (exists) {
      const index = selectionados.findIndex((x) => x == value);
      newArray.splice(index, 1);
    } else {
      newArray = [...selectionados, value];
    }

    dispatch(selectOption(props.id, newArray, nextPage));
    setSelecionados(newArray);
  };

  useEffect(() => {
    //dispatch(selectOption(props.id, 1));
  }, []);

  return (
    <>
      <h5 className="questionTitle">
        {props.id} - {props.title}
      </h5>
      {props.options.map((option) => (
        <label className="containerRadio">
          {option.label}
          <input
            type="checkbox"
            value={option.value}
            checked={selectionados.includes(option.value)}
            name={props.id}
            onChange={() => setValue(option.value, option.nextPage)}
          />
          <span class="checkmark checkbox"></span>
        </label>
      ))}
    </>
  );
}

export default RadioButtonVerticalMultiple;
