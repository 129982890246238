import React from 'react';

import { Link } from 'react-router-dom';

function Terms() {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-12"
          style={{
            textAlign: 'center',
            color: '#4c2168',
            paddingTop: 50,
          }}
        >
          <h5>TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO</h5>
          <p style={{ marginTop: 50, marginBottom: 100 }}>
            TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO
            <br />
            <br />
            Ao aceitar esse termo, você autoriza que os dados sejam utilizados
            para uma pesquisa na qual a sua identidade estará mantida em sigilo
            e todas as informações que possam identificá-lo(a) serão omitidas.
            Seus dados só estarão ao acesso dos pesquisadores e aconselhadores
            responsáveis. O benefício deste questionário será a contribuição
            pessoal para o desenvolvimento de um estudo científico e para o
            desenvolvimento de uma gestão de risco mais orientada ao
            desenvolvimento de estratégias de autocuidado.
            <br />
            <br />
            Caso você tenha qualquer dúvida quanto aos seus direitos, entre em
            contato com Comitê de Ética em Pesquisa da Pontifícia Universidade
            Católica do Rio Grande do Sul (CEP-PUCRS), em (51) 33203345, Av.
            Ipiranga, 6681/prédio 50 sala 703, CEP: 90619-900, Bairro Partenon,
            Porto Alegre – RS, e-mail: cep@pucrs.br, de segunda a sexta-feira
            das 8h às 12h e das 13h30 às 17h. Ou ainda pelo Comitê de Ética em
            Pesquisa da Secretaria Municipal de Saúde de Porto Alegre, Rua
            Capitão Montanha, 27, (51) 32895517, e-mail:
            cep-sms@sms.prefpoa.com.br.
            <br />
            <br />
            Ao clicar no botão abaixo, você reconhece que sua participação é
            voluntária, que você tem mais de 18 anos e que está ciente deste
            termo.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Link
            to="/codigo"
            className="btn btn-block btn-custom btn-lg bg-gradient"
          >
            Avançar
          </Link>
          <Link
            to="/"
            className="btn btn-block btn-custom btn-lg bg-gradient-red"
          >
            Recusar
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Terms;
