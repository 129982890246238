import { takeLatest, call, select, all } from "redux-saga/effects";
import api from "../../../services/api";

export function* saveForm(form) {
	const { id, questions } = form.payload;
	yield call(api.post, "/form", {
		id,
		questions,
	});
}

export default all([takeLatest("@form/SAVE", saveForm)]);
