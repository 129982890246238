export function nextPageRequest(history) {
  return {
    type: '@form/NEXT_PAGE_REQUEST',
    history,
  };
}
export function selectOption(question, option, nextPage) {
  return {
    type: '@form/SELECT_OPTION',
    payload: {
      question,
      option,
      nextPage,
    },
  };
}

export function saveForm(form) {
  return {
    type: '@form/SAVE',
    payload: form,
  };
}

export function resetForm() {
  return {
    type: '@form/RESET',
  };
}
